<template>
  <div class="page page-Rules" :class="{ loaded: isLoaded }">
    <div class="half half-reverse half-press content">
      <div
        class="half__pic m25"
        :style="bgImgStyle(backgroundImages)"
        v-if="!isMobile"
      ></div>
      <div class="half__text m50" style="height: auto" ref="articlesOuter">
        <div ref="articlesInner" class="articles-magic">
          <template v-for="article in showedArticles">
            <div class="article__link" :key="article.id">
              <template v-if="article.url">
                <a :href="article.url">{{
                  $i18n.locale === 'ru' ? article.titleRu : article.title
                }}</a>
              </template>
              <template v-else>
                <router-link
                  :to="{ name: 'Article', params: { id: article.id } }"
                >
                  {{ $i18n.locale === 'ru' ? article.titleRu : article.title }}
                </router-link>
              </template>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { loader } from '../mixins/loader';
import { isMobile } from '../mixins/isMobile';
import { functions } from '../mixins/functions';

import { articles, articlesby } from './articles';

import ENV from '@/environment';

export default {
  name: 'Rules',
  mixins: [loader, isMobile, functions],

  data() {
    return {
      pageData: { page: 'Rules', isHeaderHidden: true },
      backgroundImages: {
        '2x': ENV.REGION === 'by' ? 'img/by/3.png' : 'img/06@2x.jpg',
      },
      psSettings: {
        suppressScrollX: true,
      },
      articles: ENV.REGION === 'by' ? articlesby : articles,
      magicComplete: false,
    };
  },

  mounted() {
    this.checkElementHeight();
  },

  deactivated() {
    this.isLoaded = false;
  },

  activated() {
    this.$store.commit('ui/loaderON');
    this.onActivated();
  },

  watch: {
    imagesLoaded(val) {
      if (this.magicComplete && val) {
        this.pageLoaded();
      }
    },
    magicComplete(val) {
      if (this.imagesLoaded && val) {
        this.pageLoaded();
      }
    },
  },
  computed: {
    showedArticles() {
      return this.articles.filter((article) => article.isShowInCommonList);
    },
  },

  methods: {
    onActivated() {
      this.magic();
      if (this.magicComplete) {
        this.pageLoaded();
      } else {
        const images = [];

        for (const j in this.backgroundImages) {
          images.push(this.backgroundImages[j]);
        }

        this.loadImages(images, true);
      }
    },

    magic(attempt) {
      if (this.isMobile) {
        window.$('.article__link').removeAttr('style');
        this.magicComplete = true;
        return;
      }

      if (!attempt) {
        attempt = 0;

        window.$('.article__link').removeAttr('style');
      }
      this.$nextTick(() => {
        setTimeout(() => {
          const blockHeight = this.$refs.articlesOuter.clientHeight;
          const articlesHeight = this.$refs.articlesInner.clientHeight;
          const articleFontSize =
            (parseInt(window.$('.article__link:first').css('font-size')) *
              100) /
            window.$(window).width();
          const articleLineHeight =
            (parseInt(window.$('.article__link:first').css('line-height')) *
              100) /
            window.$(window).width();
          const articleMarginBottom =
            (parseInt(window.$('.article__link:first').css('margin-bottom')) *
              100) /
            window.$(window).width();

          if (articlesHeight > blockHeight && attempt < 10) {
            window.$('.article__link').css({
              'font-size': articleFontSize - 0.2 + 'vw',
              'line-height': articleLineHeight - 0.2 + 'vw',
              'margin-bottom': articleMarginBottom - 0.2 + 'vw',
            });

            // check it, анимация длится 0,3 секунды
            this.magic(++attempt);
          } else {
            this.magicComplete = true;
          }
        }, 50);
      });
    },

    checkElementHeight() {
      const articleList = this.$refs.articlesInner;

      if (
        articleList.clientHeight > window.innerHeight &&
        window.innerWidth > 767
      ) {
        articleList.style.maxHeight = '80vh';
        articleList.style.marginTop = '150px';
        articleList.style.overflowY = 'scroll';
      }
    },
  },
};
</script>
